.App {
  text-align: center;
}

/* .App-header h1 {
  font-size: 1.75em;
  margin: 0;
}

.App-header h2 {
  font-size: 1.25em;
} */

.menu {
  padding-bottom: 20px;
}

.menu--items-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.menu-item {
  background-color: #f5fedccf;
  /* border: 3px solid #CCC; */
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-width: 450px;
  text-align: left;
}
.menu-item--button {
  /* color: white; */
  padding: 10px 20px;
  border: 4px solid black;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
  background-color: greenyellow;
  color: black;
}

.menu-item--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  /* margin-top: 10px; */
}

.menu-item-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.menu-item-details {
  color: #282c34;
}

.restaurant-logo {
  /*    should be a full width banner*/
  width: 100%;
  height: auto;
  /*    must be cropped in order to limit to 40% of the viewport*/
  max-height: 40vh;
  object-fit: cover;
}

.placeholder {
  width: 100%;
  height: auto;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.fixed-footer button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.restaurant-cards,
.category-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.restaurant-card,
.category-card {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.2s;
}

.restaurant-card:hover,
.category-card:hover {
  transform: scale(1.05);
}

.clickable {
  cursor: pointer;
}
